import React, { useState, useEffect } from 'react'
import './Setting.css'
import { AreaView } from '../meImage/AreaView'
import {meSplitter} from '../Shared/meGlobal'
import {ToNotify} from 'fast_inon'
import Sections from './Sections'

const Settings = ({connected, search, role}) => {
    const [message, Message] = useState('')
    const [page, Page] = useState({entity:'Wajh', id:74, parent:0})

    const splitter = new meSplitter("Side", "MainContainer")


    const onPage = (item) => {
        //alert(`entity=${item.entity}, id=${item.id}`)
        
        Page({...page, id: item.PageId})
      }
    

    
//let _margin="'3% 5% 2% 5%'"

return (

<div className="Home" id="MainContainer" 
  onMouseDown={(e)=>{splitter.mouseDown(e)}} 
  onMouseMove={(e)=>{splitter.mouseMove(e)}} 
  onMouseUp={(e)=>{splitter.mouseUp(e)}}
>

  <div className="side vside" id="SideSection" style={{width: '60%'}}>
    <Sections onPage={onPage} search={search} Message={Message} />
  </div>
  
  <div className="side vside" id="SideImage" style={{width: '40%'}}>
    <AreaView node={page} Message={Message} />
  </div>

<ToNotify 
    message={message}
    onClose={()=>{Message('')}}
/>

</div>
        )
}

export default Settings
