import React, {useState, useRef, useEffect} from 'react'
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Header from './components/Home/Header';
import Footer from './components/Home/Footer';
import ViewAudio from './components/Shared/ViewAudio';
import Sentences from './components/Sentence/Sentences';
import Settings from './components/Setting/Settings';

function App() {
  const app = "Akademy"
  const logo = 'logo.png'
  const url ="https://moshaf.akademy.ma/waqafaat/001.mp3"

  const route = useRef("/")

  const [search, Search]  = useState("")
  const [etat, Etat]  = useState("")
  const [connected, Connect] = useState(false)

  useEffect(() => {
      let store = localStorage.getItem(app)
      //console.log('localStorage',store)
      if(store) {
          let infos = JSON.parse(store)
          
          Connect(infos?.connected)
      }
  },[])


  return (

    <BrowserRouter>

    <Header app={app} onSearch={Search} logo={logo} connected={connected} />

    <Routes>    
        <Route path='/' element={ <Home search={search} connected={connected} Etat={Etat} />} exact />
        <Route path='/sentence' element={ <Sentences connected={connected} search={search} />} />
        <Route path='/setting' element={ <Settings connected={connected} search={search} />} />
        <Route path='/audio' element={ <ViewAudio url={url} />} />
    </Routes>
      
    <Footer etat={etat}/>

    </BrowserRouter>

  )

}

export default App;
