import React, { useState, useEffect, useLayoutEffect } from 'react'
import styled from 'styled-components'
import Button from '../Shared/Button'
import {meSplitter, Etat} from '../Shared/meGlobal'
import TreeView from "../meTree/TreeView"
import {ToNotify} from 'fast_inon'

import Api from '../Shared/Api'

const Sentences = ({props}) => {
    const [message, Message] = useState('')
    const [number, Number] = useState(-1)
    const [section, Section] = useState({Id:3, Soora:'', Title:'', Items:[]})
    const [now, Now] = useState(new Date())
    const [loaded, Load] = useState(false)
    const [show, Show] = useState({i:0, n:0})

    const splitter = new meSplitter("Side", "PartContainer")

    useLayoutEffect (()=>{  // like useEffect but synchronously after all DOM
        Etat(`splitter ${splitter.items.length}`)
        
        splitter.selectorAll()

        if(section.Id>0) {
            onNode(section.Id)
        }
    },[])



    //const shuffle = (arr) => arr.sort(() => Math.random() - 0.5);

    useEffect(() => {

        if(!loaded) {

            Api.get(`moshaf/Part/${section.Id}`)
            .then(response => {
                //console.log('response',response.data)
                Section(response.data)

                //Items(response.data.Items)
                Load(true)
                Number(0)
            })
            .catch(err => console.error(err))
        }
    }, [loaded])
    

const postAnswer = (e) => {
    Load(false)
}

useEffect(() => {
    const interval = setInterval(() => {  

        Now(new Date())

    }, 1000)
    return () => clearInterval(interval)
}, []);

useEffect(()=>{
    if(number>-1 && number < (section.Items?.length??0) ) {
        Show({i:0, n:section.Items[number]?.Duration??6})
    }
},[number])

useEffect(()=>{

    if(number===section.Items.length) {
        Load(false)
        //Show({i:0, n:0})
    }
    else {
        if(show.n>0 && show.n===show.i) {
            Number(number + 1)            
        }
        else {
            Show({...show, i:show.i+1})
        }
    }

    Etat(`Show ${number} (${show.i} /  ${show.n} sec) `)
    //console.log(show)
},[now])

const onScrollTop = () => {
    if(show.i>2) {
        //alert(show.i)
    }
}

const onNode = (id) => {
    Etat(`Node ${id}`)
}



    return (

<div className="Home" id="PartContainer" 
  onMouseDown={(e)=>{splitter.mouseDown(e)}} 
  onMouseMove={(e)=>{splitter.mouseMove(e)}} 
  onMouseUp={(e)=>{splitter.mouseUp(e)}}
>

    <div className="side vside" id="SideSentence" style={{width: '70%'}}>
        <QuizWindow  onScroll={onScrollTop}>
            { loaded && section?.Items &&

                <>
                    <Question dangerouslySetInnerHTML={{ __html: `${section.Soora} - ${section.Title}` }}></Question>                    
                    
<Options>

<table>
<tbody>

                        {section?.Items.map((item, index) => (
                            <tr key={index} >

        <td >
            <Option title={`الآية ${item.Aaya} (${item.Number})`} dangerouslySetInnerHTML={loaded ? { __html: item.Content }:{ __html: null }} 
                style={{color:(number===index)?'gold':'silver'}} >
            </Option>
        </td>

                            </tr>
                        ))}

</tbody>
</table>

</Options>

                    <Button onClick={postAnswer}>مقطع جديد</Button>                      

                </>

            }
        </QuizWindow>
    </div>

    <div className="side vside" id="SideSection" style={{width: '30%'}}>
        <TreeView onChange={onNode} selected={section.Id} />
    </div>


<ToNotify 
    message={message}
    onClose={()=>{Message('')}}
/>


</div>
    )
}

export default Sentences

const QuizWindow = styled.div`
    text-align: center;
    margin-top: 1rem;
    height: auto;
    max-height: calc(100% - 1.5rem);
    overflow-y: scroll;

`;

const Options = styled.div`
    display: flex;
    direction : rtl;
    flex-direction: column;
    color: darkgreen;
    width: 90%;
    margin: 1em auto;
    
    @media screen and (min-width: 1180px) {
        width: 75%;
    }

`;

const Option = styled.button`
    display: block;
    border: 5px solid silver;
    border-radius: 15px;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: silver;
    background-color: #161A31;
    transition: 1s;
    font-size: 1.5rem;
    /*font-weight: bold;*/
    outline: none;
    user-select: none;
    margin: 0.2em;
    pointer-events: none;
    cursor: not-allowed;
    max-width: 100%;

    &:hover {
        color: white;
        background-color: #616A94;
    }
`;

const Question = styled.div`
    width: 70%;
    font-size: 2rem;
    margin: 0 auto;
    color: #161A31;
`;

