import React from 'react'
import {Route, Switch, BrowserRouter, Redirect} from 'react-router-dom'
import AudioComments from 'react-audio-comments'

const ViewAudio = ({url}) => {

    //alert(url)
    return (
<div className="page-wrapper editor">
    <p>{url}</p>

    <div>
        <AudioComments
        src={url}
        />
    </div>

  </div>
      )
}

export default ViewAudio;