import React, {useState} from 'react'
import {Row, Col, Button} from 'react-bootstrap'
import SearchControl from '../Shared/SearchControl'
import {Navigation} from '../Home/Navigation'
import SignModal from '../Sign/SignModal'

const Header = ({app, logo, onSearch, layout, connected=false}) => {
    const [showin, Showin] = useState(false)

    const Login = (e) => {
        //alert("Login from akademy.ma...")
        Showin(true)
    }

    const bstyle = { width: '6rem', display: 'block', margin:'0.2rem', fontSize:'1.2rem' }  

    return (
        
        <div className="Header" id="Header">
            <Col width="100px">
                <a href="/">
                    <img className="Logo" src={logo} alt="Home" />
                </a>
            </Col>

            <Col>            
            <Row className="navbar navbar-custom" expand="lg">
            <Col sm={6} style={{width:'50%'}}>
                <Navigation connected={connected} />
            </Col>            
            <Col sm={3}>
                { connected ?
                <SearchControl onSearch={onSearch} />
                : 
                null
                }
            </Col>
            <Col sm={3}>
                { connected ?
                    <Button style={bstyle} variant="outline-help"
                    ><i className="fa fa-sign-info"> Salaam</i></Button>
                :
                    <Button style={bstyle} variant="outline-primary"
                        onClick={Login}>
                        <i className="fa fa-sign-in"> Login</i>
                    </Button>

                }
            </Col>             

            </Row>
            </Col>

<SignModal 
    app={app}
    show={showin}
    logo={logo}
    onHide={()=>{Showin(false)}}
/> 

        </div>
        
        
    )
}

export default Header;