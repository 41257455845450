import React, { useState, useEffect } from 'react'
import {Collapse, Button} from 'react-bootstrap'
import './Setting.css'
import TableView from '../meTable/TableView'

const Sections = ({connected=true, onPage, search, role=3, Message=null}) => {

    const entity = {name:'', id:0, field:'', value:'', parent:0, open:false, rows:0} 

    const [selected, Select] = useState(entity)

    const [tables, Tables] = useState([{...entity, name:'Ajzaae', parent:1}, {...entity, name:'Section'}, {...entity, name:'Sentence'}])

    const onClose = (e) => {
        Tables(()=>{
            const temp = []
            tables.forEach(item => {
                if(item.name===e.target.name){
                    temp.push({...item, open:!item.open})
                }
                else {
                    temp.push(item)
                }
            })
            return temp
        })        
    }

    const onCell = (row) => {
        //alert(`${row.id} selected !`)
        Message && Message(`${row.id} selected !`)
        Select(row)
    }

    const onParent = (stats) => {
        tables.forEach((item,i) => {
            if(item.name===stats.name){
                if(stats?.rows>0)
                    item.rows=stats.rows
                
                if(stats?.id>0) {
                    if(i+1<tables.length) {
                        tables[i+1].parent=stats.id
                        //alert(`onParent Child=${child.name} parent=${child.parent}`)                            
                    }
                }
            }
        })
        //console.log('onParent',stats, tables)   
    }
        
return (

connected && role>2 ?

<div style={{position:"relative"}}>          

    { tables.map((item,index) => (
        <div className='CollapseTab'>         
            <Button name={item.name} onClick={(e) => onClose(e)} className='CollapseButton'>
            {item.name}s {item.rows} rows - Parent={item.parent}
            </Button>   
            <Collapse in={!item.open}>
                <div className='CollapseDiv'>
                    <TableView entity={item} onChange={onCell} onParent={onParent} onPage={onPage} />
                </div>
            </Collapse>
        </div>
    ))}

</div>             

: <h5>Not Connected</h5> 

        )
}

export default Sections
